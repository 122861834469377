<template>
  <BaseListPage locale-section="pages.parishes" route="parish">
    <ParishesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "ParishesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    ParishesList: () => import("./ParishesList")
  }
};
</script>
